const BannerFooter = () => {
  return (
    <section className="banner-footer-wrapper">
      <div className="yoga-button-container">
        <a
          href="/pesquisa?pageType=hotsite&amp;q=yoga-eco-concepcao"
          className="button button--large green"
        >
          CONFIRA A NOSSA COLEÇÃO
        </a>
      </div>
      <img
        src="https://decathlonstore.vteximg.com.br/arquivos/banner-footer-eco-yoga.jpg"
        width="100%"
        alt=""
      />
    </section>
  )
}

export default BannerFooter
