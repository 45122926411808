const EcoTimeLine = () => {
  return (
    <section className="eco-timeline-section">
      <div className="eco-timeline-year-background">
        <h3 className="yoga-2016">2016</h3>
        <h3 className="yoga-2017">2017</h3>
        <h3 className="yoga-2018">2018</h3>
        <h3 className="yoga-2019">2019</h3>
      </div>
      <div className="eco-timeline-images-1">
        <div className="timeline-segment" data-aos="fade-right">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-1p.png"
            alt=""
          />
        </div>
        <div className="timeline-segment text-and-image-timeline" />
        <div
          className="timeline-segment text-and-image-timeline"
          data-aos="fade-right"
        >
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-3.png"
            alt=""
          />
          <div className="text-timeline">
            <p className="timeline-year">2018</p>
            <p className="timeline-info">
              Lançamento de Zafu e tira em algodão orgânico
            </p>
          </div>
        </div>
        <div className="timeline-segment" />
        <div
          className="timeline-segment text-and-image-timeline"
          data-aos="fade-right"
        >
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-5.png"
            alt=""
          />
          <div className="text-timeline">
            <p className="timeline-year">2018</p>
            <p className="timeline-info">
              Novo spray para tapetes 100% natural, confeccionado com óleos
              essenciais.
            </p>
          </div>
        </div>
        <div className="timeline-segment" />
        <div className="timeline-segment">
          <div
            className="timeline-segment text-and-image-timeline-right"
            data-aos="fade-left"
          >
            <img
              className="hoodie-yoga"
              src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-7.png"
              width="65%"
              alt=""
            />
            <div className="text-timeline">
              <p className="timeline-year">2019</p>
              <p className="timeline-info">
                Moletom reciclado de garrafas plásticas
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="eco-timeline-line">
        <span className="line-segment" />
        <div className="line-dot" />
        <div className="line-dot" />
        <div className="line-dot" />
        <div className="line-dot" />
        <div className="line-dot" />
        <div className="line-dot" />
        <div className="line-dot" />
      </div>
      <div className="eco-timeline-images-2">
        <div className="timeline-segment">
          <p className="timeline-year">2016</p>
          <p className="timeline-info">
            Tapete para viagem proveniente da agricultura biológicas
          </p>
        </div>
        <div className="timeline-segment text-and-image-timeline-right">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-2.png"
            width="75%"
            alt=""
          />
          <div className="text-timeline">
            <p className="timeline-year">2017</p>
            <p className="timeline-info">Almofada em algodão orgânicos</p>
          </div>
        </div>
        <div className="timeline-segment" />
        <div className="timeline-segment">
          <div
            className="timeline-segment text-and-image-timeline-right"
            data-aos="fade-left"
          >
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-4.png"
              width="65%"
              alt=""
            />
            <div className="text-timeline">
              <p className="timeline-year">2018</p>
              <p className="timeline-info">
                Introdução de nossos tapetes Juta, um tapete 100% produzidos em
                juta e borracha natural
              </p>
            </div>
          </div>
        </div>
        <div className="timeline-segment" />
        <div
          className="timeline-segment text-and-image-timeline-right"
          data-aos="fade-right"
        >
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-6.png"
            height="75%"
            alt=""
          />
          <div className="text-timeline">
            <p className="timeline-year">2019</p>
            <p className="timeline-info">
              Tapete para viagem de algodão orgânico
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EcoTimeLine
