const YogaWorkers = () => {
  return (
    <section className="yoga-workers-2-section">
      <div className="yoga-workers-2-container">
        <div className="workers-text" data-aos="zoom-out-up">
          EM 2018, <b>1300T DE CO2* </b>
          FORAM PRESERVADOS GRAÇAS AO ALGODÃO ORGÂNICO.
          <p className="workers-text-info">
            *EQUIVALENTE A 130 VOLTAS PELO MUNDO EM CARRO!
          </p>
        </div>
        <div className="workers-green-square" data-aos="fade-down">
          <h3 className="workers-green-square-title">
            PROMOVER A PRODUÇÃO LOCAL
          </h3>
          <p className="workers-green-square-info">
            Nosso algodão é colhido na Índia e os têxteis são confeccionados
            diretamente na Índia e no Sri Lanka para evitar o impacto ambiental
            que se é produzido ao transportar o material. Nós somos inspirados
            na Índia, o berço do Yoga, onde os iogues praticam com os materiais
            naturais. Nós oferecemos essa mesma gama em todas as lojas na Índia
            e no mundo.
          </p>
          <p />
        </div>
      </div>
    </section>
  )
}

export default YogaWorkers
