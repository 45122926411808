import BannerEco from './BannerEco'
import BannerFooter from './BannerFooter'
import ContainerAgriculture from './ContainerAgriculture'
import ContainerBehind from './ContainerBehind'
import ContainerConception from './ContainerConception'
import ContainerOrganic from './ContainerOrganic'
import ContainerPartners from './ContainerPartners'
import ContainerPlastic from './ContainerPlastic'
import ContainerTimeLine from './ContainerTimeLine'
import EcoTimeLine from './EcoTimeLine'
import './styles.scss'
import YogaBioSection from './YogaBioSection'
import YogaChefSection from './YogaChefSection'
import YogaVideoSectionFooter from './YogaVideoSectionFooter'
import YogaWorkers from './YogaWorkers'

const EcoYoga = () => {
  return (
    <>
      <main className="main desk" id="main-yoga">
        <BannerEco />
        <div className="yoga-section-container">
          <YogaChefSection />
          <YogaBioSection />
          <YogaWorkers />
          <YogaVideoSectionFooter />
          <EcoTimeLine />
          <BannerFooter />
        </div>
      </main>
      <main className="dcs-ecoyoga__main mob" role="main">
        <div className="dcs-ecoyoga__container">
          <BannerEco />
          <ContainerConception />
          <ContainerPlastic />
          <ContainerOrganic />
          <ContainerPartners />
          <ContainerAgriculture />
          <ContainerBehind />
          <ContainerTimeLine />
          <BannerFooter />
        </div>
      </main>
    </>
  )
}

export default EcoYoga
