const ContainerAgriculture = () => {
  return (
    <section className="dcs-ecoyoga__container_agriculture">
      <div className="dcs-ecoyoga__container_agriculture--title">
        <h2>
          Em 2018,
          <br />
          <strong>1300T de CO2*</strong>
          <br />
          foram preservados graças ao algodão orgânico.
        </h2>
        <h3>*Equivalente a 130 voltas pelo mundo em carro!</h3>
      </div>
      <div className="dcs-ecoyoga__container_agriculture-local">
        <h3>Promover a produção local</h3>
        <p>
          Nosso algodão é colhido na Índia e os têxteis são confeccionados
          diretamente na Índia e no Sri Lanka para evitar o impacto ambiental
          que se é produzido ao transportar o material. Nós somos inspirados na
          Índia, o berço do Yoga, onde os iogues praticam com os materiais
          naturais. Nós oferecemos essa mesma gama em todas as lojas na Índia e
          no mundo.
        </p>
      </div>
    </section>
  )
}

export default ContainerAgriculture
