const ContainerPlastic = () => {
  return (
    <section className="dcs-ecoyoga__container_plastic">
      <h2 className="dcs-ecoyoga__container_plastic--title">
        CONHEÇA MAIS SOBRE A CONCEPÇÃO DO NOSSO ALGODÃO ORGÂNICO
      </h2>
      <div className="dcs-ecoyoga__container_plastic--video" />
    </section>
  )
}

export default ContainerPlastic
