const BannerEco = () => {
  return (
    <div className="banner-eco-yoga">
      <img
        className="banner-img desk"
        src="https://decathlonstore.vteximg.com.br/arquivos/banner-top-eco-yoga-desk.jpg"
        alt="Main banner eco yoga | Decathlon"
      />
      <img
        className="banner-img mob"
        src="https://decathlonstore.vteximg.com.br/arquivos/banner-top-eco-yoga-mob.jpg"
        alt="Main banner eco yoga | Decathlon"
      />
      <h2>produtos de yoga</h2>
      <div className="logo-wrapper">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/logo-ecodesign-7.png"
          alt="Logo ecodesign | Decathlon"
        />
      </div>
      <div className="hero-button">
        <i className="arrow down" />
      </div>
    </div>
  )
}

export default BannerEco
