const YogaBioSection = () => {
  return (
    <section className="yoga-bio-section">
      <div className="yoga-bio-cotton" data-aos="fade-right">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/cotton-branch.png"
          alt=""
        />
      </div>
      <div className="yoga-bio-sand" data-aos="fade-left">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/cotton-sand.jpg?v=637274975497070000"
          alt=""
        />
      </div>
      <div className="yoga-bio-container">
        <div className="yoga-bio-image-part" data-aos="fade-right">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/banner-centro-eco-yoga.jpg"
            alt=""
          />
        </div>

        <div className="yoga-bio-text-part">
          <span className="yoga-bio-year">2007</span>
          <h3 className="bio-title">
            LANÇAMENTO DA PRIMEIRA GAMA DE ALGODÃO ORGÂNICO
          </h3>
          <p className="bio-info">
            Com o suporte de nossa indústria de produção em Tirupur, na Índia,
            desenvolvemos a nossa primeira gama de tecido para soft Yoga em
            algodão orgânico. Nós estamos orgulhosos de oferecer uma gama de
            soft Yoga criada com os nossos materiais naturais.
          </p>
          <h3 className="bio-title">
            NOSSOS PARCEIROS SÃO CERTIFICADOS PARA GARANTIR UM ALGODÃO
            PROVENIENTE DA AGRICULTURA BIOLÓGICA, O ALGODÃO ORGÂNICO.
          </h3>
          <p className="bio-info">
            Nossos produtores são certificados com GOTs (Certificação Global
            para algodão orgânico) sem GMO (Organismos geneticamente
            modificados), sem pesticidas, sem a utilização de águas da chuva, na
            Índia, onde nossos colhemos o algodão orgânico..
          </p>
        </div>
      </div>
    </section>
  )
}

export default YogaBioSection
