const ContainerPartners = () => {
  return (
    <section className="dcs-ecoyoga__container_partners">
      <h2 className="dcs-ecoyoga__container_partners--title">
        Nossos parceiros são certificados para garantir um algodão proveniente
        da agricultura biológica, o algodão orgânico
      </h2>
      <p className="dcs-ecoyoga__container_partners--text">
        Nossos produtores são certificados com GOTs (Certificação Global para
        algodão orgânico) sem GMO (Organismos geneticamente modificados), sem
        pesticidas, sem a utilização de águas da chuva, na Índia, onde nossos
        colhemos o algodão orgânico.
      </p>
    </section>
  )
}

export default ContainerPartners
