const ContainerOrganic = () => {
  return (
    <section className="dcs-ecoyoga__container_organic">
      <h2 className="dcs-ecoyoga__container_organic--title">
        Lançamento da primeira gama de algodão orgânico
      </h2>
      <div className="dcs-ecoyoga__container_organic--image">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/banner-centro-eco-yoga.jpg"
          alt=""
          width="500"
          height="500"
        />
      </div>
      <p className="dcs-ecoyoga__container_organic--text">
        Com o suporte de nossa indústria de produção em Tirupur, na Índia,
        desenvolvemos a nossa primeira gama de tecido para soft Yoga em algodão
        orgânico. Nós estamos orgulhosos de oferecer uma gama de soft Yoga
        criada com os nossos materiais naturais.
      </p>
    </section>
  )
}

export default ContainerOrganic
