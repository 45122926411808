const YogaVideoSection = () => {
  return (
    <section className="yoga-video-section">
      <h3 className="bio-title">
        CONHEÇA MAIS SOBRE A CONCEPÇÃO DO NOSSO ALGODÃO ORGÂNICO
      </h3>
    </section>
  )
}

export default YogaVideoSection
