const YogaVideoSectionFooter = () => {
  return (
    <section className="yoga-video-section">
      <h3 className="bio-title">
        DESCUBRA A CONCEPÇÃO DE NOSSOS PRODUTOS{' '}
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/logo-ecodesign-7.png"
          alt="Logo Ecodesign | Decathlon"
        />
      </h3>
      <h4 className="bio-subtitle">O QUE É ECODESIGN?</h4>
      <p className="bio-description">
        Aqui na Decathlon, além de oferecer a você produtos esportivos de alto
        desempenho, nossos especialistas também se preocupam com a preservação
        do planeta para esta e as próximas gerações. Por isso, utilizamos
        materiais e produções com menos impacto à quem produz e a quem o
        utiliza, e chamamos esse produto de Eco-Design ou Produto Sustentável.
      </p>
    </section>
  )
}

export default YogaVideoSectionFooter
