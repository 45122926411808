const ContainerTimeLine = () => {
  return (
    <section className="dcs-ecoyoga__container_timeline">
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-1p.png"
            alt="Timeline 2016"
            width="500"
            height="500"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2016</h3>
          <p>Tapete para viagem proveniente da agricultura biológica</p>
        </div>
      </div>
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-2.png"
            alt="Timeline 2017"
            width="323"
            height="193"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2017</h3>
          <p>Almofada em algodão orgânico</p>
        </div>
      </div>
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-3.png"
            alt="Timeline 2018"
            width="382"
            height="234"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2018</h3>
          <p>Lançamento de Zafu e tira em algodão orgânico</p>
        </div>
      </div>
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-4.png"
            alt="Timeline 2018"
            width="286"
            height="245"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2018</h3>
          <p>
            Introdução de nossos tapetes Juta, um tapete 100% produzidos em juta
            e borracha natural
          </p>
        </div>
      </div>
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-5.png"
            alt="Timeline 2018"
            width="132"
            height="254"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2018</h3>
          <p>
            Novo spray para tapetes 100% natural, confeccionado com óleos
            essenciais.
          </p>
        </div>
      </div>
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-6.png"
            alt="Timeline 2019"
            width="226"
            height="244"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2019</h3>
          <p>Tapete para viagem de algodão orgânico</p>
        </div>
      </div>
      <div className="dcs-ecoyoga__container_timeline-item">
        <div className="dcs-ecoyoga__container_timeline-item--image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/yoga-timeline-7.png"
            alt="Timeline 2019"
            width="500"
            height="500"
          />
        </div>
        <div className="dcs-ecoyoga__container_timeline-item--text">
          <h3>2019</h3>
          <p>Moletom reciclado de garrafas plásticas</p>
        </div>
      </div>
    </section>
  )
}

export default ContainerTimeLine
