const ContainerConception = () => {
  return (
    <section className="dcs-ecoyoga__container_conception">
      <p className="dcs-ecoyoga__container_conception--description">
        No Yoga, nós somos conduzidos pelo princípio do “Ahimsa”, que prega a
        não-violência e o respeito pelo o ser humano e pelo o planeta. Para nós,
        a importância é reduzir o impacto negativo no meio ambiente. Assim, a
        escolha de materiais duráveis e responsáveis nos orienta no
        desenvolvimento de nossas coleções e nos esforçamos para trabalhar para
        que cada produto tenha um impacto ambiental menor do que o anterior. Nós
        trabalhamos com muito empenho e dedicação em nossos laboratórios de
        pesquisa e desenvolvimento bem como indústrias, para inovar em
        componentes mais duráveis e ecológicos.
      </p>
      <div className="dcs-ecoyoga__container_conception-author">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/product-chef-avatar.jpg"
          alt="Chef de Produto"
          width="100"
          height="100"
        />
        <p>Isabelle, gerente de Produto</p>
      </div>
    </section>
  )
}

export default ContainerConception
